import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UIBlock, UILayout, UIPagination } from 'bora-material-ui'
import { compose, isEmpty, pathOr, prop } from 'ramda'
import { PageTitle } from '../Common'
import NewsItem from './NewsItem'
import { getNewsItems } from '../../../services/sales-static/api'
import { mapStateToPropsForStaticPages } from '../../../services/sales-static/utils'
import { getMaritimeStyle } from '../../../utils/maritimeStyleUtils'
import messages from '../../../../src/consts/messages'
import { THEME } from 'bora-material-ui/themes'
import { getHeadingsFetcher } from '../../../services/sales-static/api/headings'
import { withRouter } from 'react-router-dom'

const styles = {
  newsFlexEnd: { alignSelf: 'flex-end', alignItems: 'flex-end', flexWrap: getMaritimeStyle('wrap', 'nowrap') },
  paginationStyles: {
    padding: '32px 0 0 0',
    xs: { 'j-flex-center': true, padding: '24px 0 0 0', hideStepButtons: true },
  },
}

const xsStyles = {
  news: {
    margin: '10px',
    width: getMaritimeStyle('100%', 'auto'),
    style: { 'align-self': 'center', margin: window.brandProps.theme === THEME.SAAREMAA ? '0' : 'inherit' },
  },
}

const tabletStyle = { width: getMaritimeStyle('100%', '55%') }

class NewsContent extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      heading: '',
      news: [],
      selectedPage: 1,
      pageCount: 1,
    }
  }

  componentDidMount() {
    this.fetchDataForThePage(this.state.selectedPage)
  }

  fetchDataForThePage = (selectedPage) => {
    getHeadingsFetcher(this.props.locale, 'news')
      .then((response) => this.setState({ heading: pathOr('', [0, 'value'])(response) }))
      .catch(() => this.setState({ heading: '' }))

    getNewsItems(this.props.locale, selectedPage)
      .then(({ items, pageCount }) => this.setState({ news: items, pageCount, selectedPage }))
      .catch(() => this.setState({ news: [] }))
  }

  styleBorderExceptLast = {
    borderBottom: 'solid 1px #b0bec5',
  }

  generateNewsItems = (news, muiTheme) =>
    news.map((item, i) => (
      <NewsItem
        key={prop('id', item)}
        date={prop('published_at', item)}
        title={prop('subject', item)}
        description={prop('body', item)}
        style={i < news.length - 1 ? this.styleBorderExceptLast : {}}
        muiTheme={muiTheme}
        locale={this.props.locale}
      />
    ))

  render() {
    const { intl, muiTheme } = this.context
    const isFullNewsPage = this.props.location.pathname.endsWith('news')

    if (isEmpty(this.state.news) && !isFullNewsPage) return null

    return (
      <UILayout
        data-test="news-content"
        width={getMaritimeStyle('100%', '680px')}
        margin={getMaritimeStyle('70px 0 0 0', '50px 20px 20px 60px')}
        style={{
          ...styles.newsFlexEnd,
          ...(muiTheme.ids.newsContent && {
            ...muiTheme.ids.newsContent,
          }),
        }}
        smm={xsStyles.news}
        display-if={this.state.news}
        pre-ms={tabletStyle}
      >
        <UIBlock bgColor="#ffffff" padding="40px 26px 10px 26px" border="none" borderRadius="4px">
          <UILayout column>
            <PageTitle title={this.state.heading} />
            <UILayout column data-testid="news-items-holder">
              {this.state.news ? (
                this.generateNewsItems(this.state.news, muiTheme)
              ) : (
                <UILayout j-flex-center margin="60px 0">
                  <CircularProgress />
                </UILayout>
              )}
            </UILayout>
            <UIPagination
              display-if={!this.props.hidePagination && this.state.pageCount > 1}
              paginationStyle={styles.paginationStyles}
              prevText={intl.formatMessage(messages.previousPagination)}
              nextText={intl.formatMessage(messages.nextPagination)}
              onSelectPage={this.fetchDataForThePage}
              selectedPage={this.state.selectedPage}
              pageCount={Number(this.state.pageCount)}
            />
          </UILayout>
        </UIBlock>
      </UILayout>
    )
  }
}

NewsContent.propTypes = {
  locale: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
  hidePagination: PropTypes.bool,
}

NewsContent.defaultProps = {
  pageTitle: '',
  hidePagination: false,
}

NewsContent.contextTypes = {
  intl: PropTypes.object,
  muiTheme: PropTypes.object,
}

const enhance = compose(withRouter, connect(mapStateToPropsForStaticPages))

export default enhance(NewsContent)
